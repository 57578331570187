import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Image from 'gatsby-image'
import styled, { createGlobalStyle } from 'styled-components'
import theme from '../const/theme'
import Footer from './footer'
import { Helmet } from 'react-helmet'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const StyledLogo = styled(AniLink)`
  width: 300px;
  display: block;
  margin: 5rem auto 2rem;
`

const StyledSection = styled.section`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
`

const GlobalStyle = createGlobalStyle`

  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.gray_darker};
    font-style: italic;
    font-weight: normal;
    font-family: "ff-nexus-mix", san-serif;
  }
  
  a {
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: .8;
      transition: opacity .2s ease-in-out;
    }
  }
`

const Layout = ({ title, children }) => {
  const data = useStaticQuery(logoQuery)
  let header = (
    <StyledLogo fade duration={0.5} to={'/'}>
      <Image fluid={data.logo.childImageSharp.fluid} placeholderStyle={{filter: `blur(10px)`}} alt={title} />
    </StyledLogo>
  )

  return (
    <main>
      <GlobalStyle />
      <Helmet>
        {/*<link*/}
        {/*  href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"*/}
        {/*  rel="stylesheet"*/}
        {/*/>*/}
        <link rel="stylesheet" href="https://use.typekit.net/jek6yes.css" />
      </Helmet>
      {header}
      <StyledSection>{children}</StyledSection>
      <Footer />
    </main>
  )
}

export default Layout

const logoQuery = graphql`
  query {
    logo: file(absolutePath: { regex: "/site-logo.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
