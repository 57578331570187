import React from 'react'
import styled from 'styled-components'
import theme from '../const/theme'
import { useStaticQuery, graphql } from 'gatsby'

const FooterInner = styled.div`
  opacity: 0.7;
  max-width: ${theme.pageWidth};
  margin: 0 auto;
  text-align: center;
`

const StyledFooter = styled.footer`
  background: ${theme.colors.gray};
  padding: 2rem 0;
`

const SocialWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
`

const SocialLink = styled.a`
  display: block;
  width: 1.5rem;
  margin: 0 0.5rem;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
`

const Footer = () => {
  const data = useStaticQuery(pageQuery).site.siteMetadata.social
  return (
    <StyledFooter>
      <FooterInner>
        <div>
          <small>
            © {new Date().getFullYear()} Michelle Yuen; All Rights Reserved
          </small>
        </div>
        <SocialWrapper>
          {data.map((social) => {
            return (
              <SocialLink
                href={social.url}
                target="_blank"
                rel="nofollow"
                key={social.name}
              >
                <img src={`/${social.name}.svg`} alt={social.name} />
              </SocialLink>
            )
          })}
        </SocialWrapper>
      </FooterInner>
    </StyledFooter>
  )
}

export default Footer

const pageQuery = graphql`
  query MyQuery {
    site {
      siteMetadata {
        social {
          name
          url
        }
      }
    }
  }
`
